import type { IconName } from '@st/ui/components/StIcon/types'
import type { Notification, CurrencyNotification } from '../types'
import { useProfileDeps } from '../useDeps'

export function useCurrencyNotification(notification: Ref<Notification>) {
  function isCurrencyNotificationGuard(
    notificationItem: Ref<Notification>,
  ): notificationItem is Ref<CurrencyNotification> {
    return 'amount' in notificationItem.value.data
  }

  const { format: formatWithCurrency } = useCryptoFormatter({
    currency: 'auto',
  })
  const { getCurrencyCodeById, getCurrencyIconById } = useProfileDeps()

  const notificationCurrencyCode = computed(() => {
    if (!isCurrencyNotificationGuard(notification)) return undefined

    /* 
      бэк уберет currencyCode из всех нотификаций
      и заменит на currencyId
    */
    return (
      getCurrencyCodeById(notification.value.data.currencyId) ||
      notification.value.data.currencyCode
    )
  })

  const notificationAmount = computed(() =>
    isCurrencyNotificationGuard(notification)
      ? formatWithCurrency(
          notification.value.data.amount,
          notificationCurrencyCode.value,
        )
      : '',
  )

  const currencyIcon = computed((): IconName | '' =>
    isCurrencyNotificationGuard(notification)
      ? getCurrencyIconById(notification.value.data.currencyId)
      : '',
  )

  return {
    notificationAmount,
    currencyIcon,
  }
}
